body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.emoji-mart-bar {
  display: none;
}

.custom-drawer .MuiDrawer-paperAnchorLeft{
  top: 16px;
  bottom: 16px;
  border-radius: 8px;
  left: 16px;
  height: auto;
  min-width: 500px;
}

@media (max-width: 767px) {
  .custom-drawer .MuiDrawer-paperAnchorLeft{
    top: 0;
    bottom: 0;
    border-radius: 8px;
    left: 0;
    height: auto;
    min-width: 0;
    width: 100%;
  }
}

input {
  font-size: 14px !important;
}

h5 {
  font-size: 14px;
  font-weight: normal;
}

.custom-drawer .MuiBackdrop-root {
  background: transparent;
}

#emoji-palette {
  border: 1px solid rgba(0,0,0,.15);
  box-shadow: 0 5px 10px rgb(0 0 0 / 12%);
}

#emoji-close-div {
  display: none !important;
}

#dialog480 .MuiDialog-paper {
  max-width: 480px !important;
}